.wizard {
  >.content >.title, >.steps .current-info {
    display: none;
  }

  .steps {
    @extend .list-inline;

    li {
      width: 25%;
      display: inline-block;
    }

    .number {
      border: 1px solid $color-light-gray;
      border-radius: 50%;
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      margin-right: 10px;
    }

    a {
      color: $color-light-gray;
    }

    a:hover {
      color: black;

      .number {
        border-color: #000;
      }
    }

    .current, .done {
      a {
        color: $color-blue;
      }

      .number {
        border: 0;
        background: $color-blue;
        color: white;
      }
    }

    li {
      line-height: 50px;
      font-size: 20px;
      font-family: $font-family-headers;
      text-transform: uppercase;

      a:hover, a:active, a:focus {
        text-decoration: none;
      }
    }
  }

  .actions {
    @extend .row;
    padding: 20px 0 30px 0;

    ul {
      @extend .col-md-12;
    }

    li {
      @extend .list-inline;

      a {
        @extend .btn;
        @extend .btn-black;
        @extend .btn-block;
        @extend .btn-lg;
        color: white;
      }

      &:first-child {
        display: none;
      }
    }
  }

  .content {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-top: 20px;
  }
}