.btn {
  padding: 6px 25px;

  @media (max-width: $screen-md-min) {
    white-space: normal
  }
}

.btn-black {
  background: #000;
  color: white;

  &:hover {
    color: white;
  }
}

.btn-lg {
  padding: 15px 25px;
}

.btn--tab {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-block;
  @extend .btn-default;
  white-space: normal;
  font-family: $font-family-headers;
  font-weight: 400;
  padding: 15px 40px;

  &.btn-default {
    color: $color-dark-gray;

    &:hover {
      color: black;
      border-color: black;
      background-color: white;
    }
  }
}

.btn--tab-med {
  @extend .btn--tab;
  padding: 17px 60px;
}

li.active .btn--tab {
  @extend .btn-primary;
}

.btn--break {
  white-space: normal;
  text-align: left;
}
