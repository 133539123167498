.title--page {
  border-top: 1px solid $color-dark-gray;
  margin: 20px 0;
  text-align: center;

  h3 {
    margin-top: -23px;
  }

  span {
    background: $color-bg;
    padding: 0 $space-xx-lg;
  }
}

