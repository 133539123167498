.footer {
  h4 {
    color: white;
  }

  li {
    font-size: 14px;

    a {
      color: black;
    }
  }

  .list--vspaced li {
    color: white;

    strong,
    a {
      color: white;
    }
  }
}
