.social {
  min-width: 150px;
  color: white;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
}

.social--facebook {
  background: #3A5998
}

.social--twitter {
  background: #1DADEB;
}

.social--youtube {
  background: #D34134;
}

.social--instagram {
  background: black;
}
