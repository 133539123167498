.nav--form {
  @extend .list-inline;

  strong {
    border: 1px solid $color-light-gray;
    border-radius: 50%;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    margin-right: 10px;

    &:hover {
    }
  }

  a {
    color: $color-light-gray;
  }

  a:hover {
    color: black;

    strong {
      border-color: #000;
    }
  }

  .active {
    a {
      color: $color-blue;
    }

    strong {
      border: 0;
      background: $color-blue;
      color: white;
    }
  }

  li {
    line-height: 50px;
    font-size: 20px;
    font-family: $font-family-headers;
    text-transform: uppercase;

    a:hover, a:active, a:focus {
      text-decoration: none;
    }
  }
}
