.box--bg {
  background: $color-bg;
  padding: 40px 0;
}

.box--green {
  text-align: center;
  display: table-cell;
  background: $color-green;
  color: white;
  text-transform: uppercase;
  min-width: 240px;
  padding: 20px 0;
  border-radius: 5px;
}
