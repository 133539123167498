.navbar {
    @media (min-width: $screen-md-min) {
        float: right;
        padding-top: 41px;
    }

    @media (max-width: $screen-md-min) {
        > li {
            display: block;
            padding: 10px 0;

            a {
                text-align: center;
                display: block;
            }
        }
    }

    a {
        color: #000;
        font-family: $font-family-headers;
        font-size: 19px;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
}
