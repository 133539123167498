.bg--primary, .bg--blue {
  background-color: $brand-primary;
}

.bg--red {
  background-color: $color-red;
}

.bg--orange {
  background-color: $color-orange;
}

.bg--green {
  background-color: $color-green;
}

.bg--dark_gray {
  background-color: $color-dark-gray;
}

.bg--light_gray {
  background-color: $color-light-gray;
}

.bg--default_gray {
  background-color: $color-bg;
}

.bg--default_gray--darker {
  background-color: $color-dark-gray-darker;
}
