.content {
  @extend .col-md-10;
  @extend .col-md-offset-1;
}

.content--negative {
  margin-top: -100px;
  background: white;
  padding-top: 50px;
}
