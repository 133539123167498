.flickity-left .flickity-page-dots {
  width: auto;
  margin-left: 18px;
}

.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: white;
  border: 2px solid $color-dark-gray;
}

.flickity-page-dots .dot.is-selected {
  background: $color-dark-gray;
}
