/* Colors */
$color-red: #E02227;
$color-green: #73BE44;
$color-orange: #F59221;
$color-blue: #0271A1;
$color-dark-gray: #A2A2A2;
$color-dark-gray-darker: #222;
$color-light-gray: #E8E8E8;
$color-bg: #F2F2F2;
$font-family-headers: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-text: "Lora", "Helvetica Neue", Helvetica, Arial, sans-serif;

/* Bootstrap */
$grid-gutter-width: 50px;
$brand-primary: $color-blue;
$btn-success-bg: $color-green;
$btn-warning-bg: $color-orange;
$btn-danger-bg: $color-red;
$input-border: #000;

/* Spaces Definitions */
$space-xx-sm : 1px;
$space-x-sm  : 3px;
$space-sm    : 5px;
$space-md    : 10px;
$space-lg    : 20px;
$space-x-lg  : 40px;
$space-xx-lg : 60px;
