.list--hspaced li {
  padding-right: $space-md;
}

.list--vspaced li {
  padding: $space-sm 0;
}

.list--courses {
  border-top: 1px solid $color-red;
  border-bottom: 1px solid $color-red;
  padding: 10px 0;

  ul {
    @extend .list-inline;
    @extend .list--hspaced;
    @extend .text--red;

    @media (max-width: $screen-md-min) {
      li {
        text-align: center;
        display: block;
        padding: 10px;
        a {
          font-size: 20px
        }
      }
    }
  }
}

.list--nav {
  @extend .list-unstyled;
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  line-height: 30px;

  @media (max-width: $screen-md-min) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  a {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
}

.list--sub {
  display: none;
}

.list--filter li {
  font-size: 14px;
}

.list--lines ul {
  @extend .list-unstyled;
}

.list--lines ul,
.list--lines ol, {
  border-top: 1px solid $color-light-gray;

  > li {
    border-bottom: 1px solid $color-light-gray;
    padding: 15px 0;
    color: $color-blue;
  }
}

.list--numbers {
  counter-reset: my-counter;

  > li {
    @extend .clearfix;
    padding: 10px 0;

    &:before {
      content: counter(my-counter);
      counter-increment: my-counter;

      color: $color-blue;
      font-size: 36px;
      font-family: $font-family-headers;
      font-weight: 100;
      width: 40px;
      float: left;
    }
  }
}

.list--marker {
  color: $color-blue;
  font-size: 36px;
  font-family: $font-family-headers;
  font-weight: 100;
  width: 40px;
  float: left;
}

.list--content {
  margin-left: 40px;
  padding-top: 5px;
}

.list--video_gallery {
  @extend .list-unstyled;
  padding: 140px 0 140px 100px;
  border-left: 1px solid $color-light-gray;

  li {
    border-bottom: 1px solid $color-light-gray;
    padding: 20px 0;

    &:last-child {
      border-bottom: 0;
    }

    h5 {
      font-weight: 100;

    }

    a {
      color: black;
    }

    &.active a {
      color: $color-red;
    }
  }
}

.list--tabs {
  @extend .list-inline;

  a {
    color: $color-dark_gray;
    font-family: $font-family-headers;
    font-size: 48px;

    @media (min-width: $screen-md-min) {
      padding-right: 20px;
    }

    &:active, &:focus, &:hover {
      text-decoration: none;
    }
  }

  li.active a {
    color: $color-blue;
  }
}

.list--tabs-outlined {
    a {
        font-size: 24px;
        padding: 5px 10px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
    }

    li.active a {
        border: 1px solid $color-blue;
    }
}

.list--tabs-red {
  @extend .list--tabs;

  li.active a {
    color: $color-red;
  }
}
