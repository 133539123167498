@media (min-width: $screen-md-min) {
    body {
        font-size: 160%;
    }
}

@media (max-width: $screen-md-min) {
    body {
        font-size: 120%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-headers;
}

h1 {
    font-size: 4em;
}

h2 {
    font-size: 3em;
}

h3 {
    font-size: 2em;
    line-height: 1.3em;
}

h4 {
    font-size: 20px;
    line-height: 1.3em;
}

h5 {
    font-size: 16px;
}

body,
p,
li,
table,
td {
    font-family: $font-family-text;
}

body {
    color: #1f1f1f;
}

small {
    font-size: 14px;
}

.text--negative {
    color: #fff;
}

.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--introduction {
    font-size: 26px;
}

.text--description p {
    font-size: 16px;
    padding: 10px 0;
}

.text--blue,
a.text--blue,
.text--hover-blue:hover,
.text--hover-blue.active {
    color: $color-blue;
}

.text--vestibular-2019,
a.text--vestibular-2019,
.text--hover-vestibular-2019:hover,
.text--hover-vestibular-2019.active {
    color: #e65660;
}

.text--red,
a.text--red,
.text--hover-red:hover,
.text--hover-red.active {
    color: $color-red;
}

.text--orange,
a.text--orange,
.text--hover-orange:hover,
.text--hover-orange.active {
    color: $color-orange;
}

.text--green,
a.text--green,
.text--hover-green:hover,
.text--hover-green.active {
    color: $color-green;
}
.text--dark_gray,
a.text--dark_gray,
.text--hover-dark_gray:hover,
.text--hover-dark_gray.active {
    color: $color-dark-gray;
}

.text--light_gray,
a.text--light_gray,
.text--hover-light_gray:hover,
.text--hover-light_gray.active {
    color: $color-dark-gray;
}

.text--black,
a.text--black,
.text--hover-black:hover,
.text--hover-black.active {
    color: black;
}

.text--ellipsis {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text--highlight p {
    font-size: 24px;
}

.text--hidden {
    height: 500px;
    overflow: hidden;
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 70%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
}

.text--thin {
    font-weight: 100;
}

.text--bold {
    font-weight: bold;
}

.text--headers--blue {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-blue;
    }
}

.text--headers--red {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-red;
    }
}

.text--headers--green {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-green;
    }
}

.text--headers--orange {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-orange;
    }
}

.text--headers--green {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-green;
    }
}

.text--family--headers {
    font-family: $font-family-headers;
}

blockquote {
    padding: 10px 100px;
    margin: 0 0 20px;
    font-size: 20px;
    border-left: none;
    color: #a2a2a2;
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";

    &:before {
        color: #a2a2a2;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
}
