.news--title {
  padding-bottom: $space-md;
  height: 90px;

  a {
    color: black;

    &:hover {
      color: $color-green;
      text-decoration: none;
    }
  }
}

.news--box {
  padding-bottom: 10px;
}

.news--date {
  color: $color-dark-gray;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news--tags {
  font-style: italic;
  position: relative;

  :before {
    top: 0;
    display: block;
    position: absolute;
    width: 80px;
    background: $color-green;
    height: 3px;
    content: " ";
  }

  span:last-child {
    display: none;
  }
}

.share-hover {
  position: relative;
  cursor: pointer;
}

.share-hover:hover {
  .share-toolbox {
    display: block;
  }
}

.share-toolbox {
  display: none;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  right: -45px;
  top: -80px;
  width: 65px;
  background: white;
  padding: 10px 10px;
  box-shadow: 0px 0px 19px -1px rgba(158, 158, 158, 0.75);
  height: 205px;
}

.news--image_radius {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.news--image {
  margin: 0 auto;

  @media (min-width: $screen-md-min) {
    height: 266px;
  }
}

.news--title__highlight {
  min-height: 122px;
  padding-top: 20px;
}
