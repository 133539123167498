.form-control {
  height: 42px;
  padding: 12px;

  &:focus {
    box-shadow: none;
    border-color: $color-blue;
  }
}

.form-group {
  padding: 10px 0;
}

label {
  font-size: 20px;
  font-weight: normal;
}

.radio label {
  padding-left: 40px;

  &:first-child {
    padding-left: 20px;
  }
}

.form--clean {
  border: none;
  color: $color-dark-gray;
  background: #fff;
}

.form-group {
  label.error {
    color: red;
  }

  input.error, select.error, textarea.error {
    border-color: red;
  }
}
