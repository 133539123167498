@import '../variables.scss';

.ptn, .pvn, .pan {
  padding-top: 0
}

.ptx, .pvx, .pax {
  padding-top: $space-x-sm
}

.pts, .pvs, .pas {
  padding-top: $space-sm
}

.ptm, .pvm, .pam {
  padding-top: $space-md
}

.ptl, .pvl, .pal {
  padding-top: $space-lg
}

.ptxl, .pvxl, .paxl {
  padding-top: $space-x-lg
}

.ptxxl, .pvxxl, .paxxl {
  padding-top: $space-xx-lg
}

.prn, .phn, .pan {
  padding-right: 0
}

.prx, .phx, .pax {
  padding-right: $space-x-sm
}

.prs, .phs, .pas {
  padding-right: $space-sm
}

.prm, .phm, .pam {
  padding-right: $space-md
}

.prl, .phl, .pal {
  padding-right: $space-lg
}

.prxl, .phxl, .paxl {
  padding-right: $space-x-lg
}

.prxxl, .phxxl, .paxxl {
  padding-right: $space-xx-lg
}

.pbn, .pvn, .pan {
  padding-bottom: 0
}

.pbx, .pvx, .pax {
  padding-bottom: $space-x-sm
}

.pbs, .pvs, .pas {
  padding-bottom: $space-sm
}

.pbm, .pvm, .pam {
  padding-bottom: $space-md
}

.pbl, .pvl, .pal {
  padding-bottom: $space-lg
}

.pbxl, .pvxl, .paxl {
  padding-bottom: $space-x-lg
}

.pbxxl, .pvxxl, .paxxl {
  padding-bottom: $space-xx-lg
}

.pln, .phn, .pan {
  padding-left: 0
}

.plx, .phx, .pax {
  padding-left: $space-x-sm
}

.pls, .phs, .pas {
  padding-left: $space-sm
}

.plm, .phm, .pam {
  padding-left: $space-md
}

.pll, .phl, .pal {
  padding-left: $space-lg
}

.plxl, .phxl, .paxl {
  padding-left: $space-x-lg
}

.plxxl, .phxxl, .paxxl {
  padding-left: $space-xx-lg
}

.mtn, .mvn, .man {
  margin-top: 0px
}

.mtx, .mvx, .max {
  margin-top: $space-x-sm
}

.mts, .mvs, .mas {
  margin-top: $space-sm
}

.mtm, .mvm, .mam {
  margin-top: $space-md
}

.mtl, .mvl, .mal {
  margin-top: $space-lg
}

.mtxl, .mvxl, .maxl {
  margin-top: $space-x-lg
}

.mtxxl, .mvxxl, .maxxl {
  margin-top: $space-xx-lg
}

.mrn, .mhn, .man {
  margin-right: 0
}

.mrx, .mhx, .max {
  margin-right: $space-x-sm
}

.mrs, .mhs, .mas {
  margin-right: $space-sm
}

.mrm, .mhm, .mam {
  margin-right: $space-md
}

.mrl, .mhl, .mal {
  margin-right: $space-lg
}

.mrxl, .mhxl, .maxl {
  margin-right: $space-x-lg
}

.mrxxl, .mhxxl, .maxxl {
  margin-right: $space-xx-lg
}

.mbn, .mvn, .man {
  margin-bottom: 0
}

.mbx, .mvx, .max {
  margin-bottom: $space-x-sm
}

.mbs, .mvs, .mas {
  margin-bottom: $space-sm
}

.mbm, .mvm, .mam {
  margin-bottom: $space-md
}

.mbl, .mvl, .mal {
  margin-bottom: $space-lg
}

.mbxl, .mvxl, .maxl {
  margin-bottom: $space-x-lg
}

.mbxxl, .mvxxl, .maxxl {
  margin-bottom: $space-xx-lg
}

.mln, .mhn, .man {
  margin-left: 0
}

.mlx, .mhx, .max {
  margin-left: $space-x-sm
}

.mls, .mhs, .mas {
  margin-left: $space-sm
}

.mlm, .mhm, .mam {
  margin-left: $space-md
}

.mll, .mhl, .mal {
  margin-left: $space-lg
}

.mlxl, .mhxl, .maxl {
  margin-left: $space-x-lg
}

.mlxxl, .mhxxl, .maxxl {
  margin-left: $space-xx-lg
}
