.galleryElement__item {
    display: block;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.galleryElement__itemBox {
    overflow: hidden;
    height: 190px;
    width: 287px;
    float: left;
    padding: 0;
    margin: 0 5px 10px 5px;

    &:nth-child(4n) {
        margin-right: 0;
    }
}

