.layout--logo {
    width: 110px;
    height: auto;
    margin-top: -10px;

    @media (max-width: $screen-md-min) {
        margin: 0 auto;
        display: block;
    }
}

.layout--logo50anos {
    width: 356px;
    height: 100%;
    margin-top: -14px;

    @media (max-width: $screen-md-min) {
        margin: 0 auto;
        display: block;
        width: 277px;
    }
}
