.bn {
  height: 360px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bn--large {
  @extend .bn;
  height: 475px;
}

.bn--medium {
  @extend .bn;
  height: 394px;
}

.bn--box {
  font-size: 48px;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  color: white;
  font-family: $font-family-headers;
  text-transform: uppercase;
  font-weight: 300;
  display: table;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;

  @media (min-width: $screen-md-min) {
    max-width: 346px;
  }

  @media (max-width: $screen-md-min) {
    margin-bottom: 20px;
  }
}

.bn--box a {
  color: white;
  display: table-cell;
  height: 140px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    text-decoration: none;
  }
}

.bn--box__orange {
  @extend .bn--box;
  background: #fd746c; /* Old browsers */
  background: -moz-linear-gradient(left, #fd746c 0%, #ff9068 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #fd746c 0%,#ff9068 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #fd746c 0%,#ff9068 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd746c', endColorstr='#ff9068',GradientType=0 );

  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px #fd746c;
    -moz-box-shadow: 0px 0px 10px 0px #fd746c;
    box-shadow: 0px 0px 10px 0px #fd746c;
  }
}

.bn--box__blue {
  @extend .bn--box;
  background: #c2e59c; /* Old browsers */
  background: -moz-linear-gradient(left,  #c2e59c 0%, #64b3f4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #c2e59c 0%,#64b3f4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #c2e59c 0%,#64b3f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c2e59c', endColorstr='#64b3f4',GradientType=0 ); /* IE6-9 */

  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px #64b3f4;
    -moz-box-shadow: 0px 0px 10px 0px #64b3f4;
    box-shadow: 0px 0px 10px 0px #64b3f4;
  }
}

.bn--box__yellow {
  @extend .bn--box;
  background: #f8b500; /* Old browsers */
  background: -moz-linear-gradient(left,  #fceabb 0%, #f8b500 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #fceabb 0%, #f8b500 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #fceabb 0%, #f8b500 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8b500', endColorstr='#fceabb',GradientType=0 ); /* IE6-9 */

  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px #f8b500;
    -moz-box-shadow: 0px 0px 10px 0px #f8b500;
    box-shadow: 0px 0px 10px 0px #f8b500;
  }
}

.bn--news {
  margin: 0 auto;
}

.bn--group {
  height: 476px;
  overflow: hidden;

  .bn {
    background-size: cover;
    margin-bottom: 10px;
    background-position: center center;
  }

  .bn--col {
    float: left;
    padding: 0 5px;
  }

  .bn--col-sm {
    @extend .bn--col;
    width: 33.33%;
  }

  .bn--col-md {
    @extend .bn--col;
    width: 50%;
  }

  .bn--col-lg {
    @extend .bn--col;
    width: 66.66%;
  }

  .bn--col-full {
    @extend .bn--col;
    width: 100%;
  }

  .bn--h-sm {
    @extend .bn;
    height: 186px;
  }

  .bn--h-md {
    @extend .bn;
    height: 280px;
  }

  .bn--h-lg {
    @extend .bn;
    height: 476px;
  }
}

.bn--row {
  margin: 0 -5px;
}

.bn--default {
  @media (min-width: $screen-md-min) {
    min-height: 344px;
  }
}
